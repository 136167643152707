import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import get from 'lodash/get'
import LoadingPage from '../src/LoadingPage'
import { useCaptureAuthError } from '../src/auth/useCaptureAuthError'
import { useCurrentUser } from '../src/hooks/useCurrentUser'

export default function Root() {
  const { currentUser } = useCurrentUser()
  const { replace } = useRouter()
  useCaptureAuthError()

  useEffect(() => {
    if (currentUser) {
      const lastVisitedOrganizationSlug = Cookies.get(`${currentUser.id}_lastVisitedOrganization`)
      const allOrganizations = get(currentUser, 'members.nodes', []).map(
        (node) => node.organization.slug,
      )

      if (lastVisitedOrganizationSlug && allOrganizations.includes(lastVisitedOrganizationSlug)) {
        replace(lastVisitedOrganizationSlug)
      } else {
        replace(currentUser?.personalOrganization?.slug || '')
      }
    }
  }, [currentUser])

  return <LoadingPage />
}
